module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":960,"wrapperStyle":"margin-left: initial;","backgroundColor":"transparent","quality":70,"withWebp":true,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"分かりやすい技術ブログ","short_name":"分か技ブログ","start_url":"/","background_color":"#ffffff","display":"minimal-ui","icon":"static/favicon.webp","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"6b81d2fa130d9edb038b1cde3f3c4bc0"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
